import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSmoothScroll from 'vue2-smooth-scroll'
import Unicon from 'vue-unicons'
import {
  uniAward, uniSmileBeam, uniFastMail, uniArrowGrowth, uniEnvelope,
  uniMapMarker, uniWhatsapp
} from 'vue-unicons/src/icons'

Unicon.add([uniAward, uniSmileBeam, uniFastMail, uniArrowGrowth, uniEnvelope,
  uniMapMarker, uniWhatsapp])
Vue.use(Unicon)
Vue.use(VueSmoothScroll, { updateHistory: false })
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  // router,
  render: h => h(App)
}).$mount('#app')
