<template>
  <div id="contact-us">
    <div class="bg-color">
      <b-container>
        <b-row>
          <b-col class="mx-auto text-center" sm="8">
            <h1>Work With Us</h1>
            <hr />
            <p>
              We are able to create any kind of solution based on <br />web and
              mobile technology.
            </p>
            <a
              href="https://wa.me/6281334726516"
              target="_blank"
              class="btn btn-lg btn-join"
              >Discuss Project</a
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>
<style scoped>
#contact-us {
  background-image: url("~@/assets/img/bg-contact.jpg");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}
#contact-us .bg-color {
  background-color: #000000;
  opacity: 0.9;
  padding: 8rem 0;
}
#contact-us h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
#contact-us p {
  color: #fefefe;
}
hr {
  border-top: 1px solid #7f6f8e;
  width: 20%;
}
a.btn-join {
  background-color: #00b0ff;
  color: #ffffff;
  border-radius: 28px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
}
a.btn-join:hover {
  background-color: #4169e1;
  transition: all 0.4s ease-in-out;
}
</style>