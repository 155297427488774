<template>
  <div id="app">
    <Navbar />
    <Hero />
    <CoreValue />
    <Product />
    <Client />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/layout/Navbar.vue";
import Hero from "./components/home/Hero.vue";
import CoreValue from "./components/home/CoreValue.vue";
import Product from "./components/home/ProductB.vue";
import Client from "./components/home/Client.vue";
import Contact from "./components/home/ContactUs.vue";
import Footer from "./components/layout/Footer.vue";
export default {
  components: { Navbar, Hero, CoreValue, Product, Client, Contact, Footer }
};
</script>

