<template>
  <div id="clients">
    <b-container>
      <h1 class="text-center">Our Clients</h1>
      <b-row class="text-center">
        <b-col md="12" class="logo-partners">
          <b-img
            class="logo-partner-1"
            :src="require('@/assets/logo-clients/logo-bumn.png')"
            width="150"
          >
          </b-img>
          <b-img
            class="logo-partner-2"
            :src="require('@/assets/logo-clients/mrt.png')"
            width="150"
          >
          </b-img>
          <b-img
            class="logo-partner-3 mandiri"
            :src="require('@/assets/logo-clients/logo-mandiri.png')"
            width="180"
          >
          </b-img>
          <b-img
            class="logo-partner-3 garuda"
            :src="require('@/assets/logo-clients/logo-garuda.png')"
            width="160"
          >
          </b-img>
          <b-img
            class="logo-partner-3 telkom"
            :src="require('@/assets/logo-clients/telkom.png')"
            width="160"
          >
          </b-img>
          <b-img
            class="logo-partner-3 pelita"
            :src="require('@/assets/logo-clients/pelita.png')"
            width="180"
          >
          </b-img>
          <b-img
            class="logo-partner-3 telkomsel"
            :src="require('@/assets/logo-clients/telkomsel.png')"
            width="180"
          >
          </b-img>
          <b-img
            class="logo-partner-3 inmed"
            :src="require('@/assets/logo-clients/inmed.png')"
            width="180"
          >
          </b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#clients {
  padding: 8rem 0;
}
#clients h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #191919;
  margin-bottom: 4rem;
}
#clients h4 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
#clients p {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.6;
}
#clients strong {
  color: #2e74ff;
}
a.btn-join {
  background-color: #2e74ff;
  color: #ffffff;
  border-radius: 28px;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}
a.btn-join:hover {
  background-color: #0965c7;
  transition: all 0.4s ease-in-out;
}
.logo-partners img {
  margin: 0 3rem;
}
@media only screen and (max-width: 600px) {
  #clients {
    text-align: center;
  }
  #clients h1 {
    font-size: 2rem;
  }
  #clients p {
    font-size: 1rem;
  }
  a.btn-join {
    font-size: 1rem;
  }
  .logo-partners {
    margin-top: 3rem;
  }
  .mandiri,
  .garuda,
  .telkom,
  .pelita {
    margin-bottom: 4rem !important;
  }
  .telkomsel {
    margin-bottom: 0 !important;
  }
  .logo-partners img {
    margin-bottom: 2rem;
  }
}
</style>