<template>
  <div>
    <b-navbar toggleable="lg" id="nav" :class="{ gosticky: active }">
      <b-container>
        <b-navbar-brand href="#">
          <div class="my-brand">
            <img :src="logo" alt="Logo Cendana 2000" width="200" />
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              ><a href="#hero-section" v-smooth-scroll>Home</a>
            </b-nav-item>
            <b-nav-item
              ><a href="#product" v-smooth-scroll="{ offset: -100 }"
                >Products</a
              >
            </b-nav-item>
            <b-nav-item
              ><a href="#clients" v-smooth-scroll="{ offset: -100 }">Clients</a>
            </b-nav-item>
            <b-nav-item
              ><a href="#contact-us" v-smooth-scroll="{ offset: -60 }"
                >Contact Us</a
              >
            </b-nav-item>
          </b-navbar-nav>
          <!-- <b-navbar-nav class="ml-auto">
            <b-nav-item href="#"
              ><a href="https://wa.link/g9l4o3" class="btn-contact-nav"
                >Hubungi Kita</a
              ></b-nav-item
            >
          </b-navbar-nav> -->
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo-cendana-white.png"),
      active: false,
    };
  },
  methods: {
    toggleNavClass() {
      if (this.active == false) {
        return "nav";
      } else {
        return "sticky-nav";
      }
    },
  },
  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById("nav");
      if (window.scrollY > navBar.offsetTop) {
        this.active = true;
        this.logo = require("@/assets/logo-cendana.png");
      } else {
        this.active = false;
        this.logo = require("@/assets/logo-cendana-white.png");
      }
    };
  },
};
</script>

<style scoped>
.navbar {
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 0.5rem 1rem;
}

.navbar-brand img {
  margin-right: 0.5rem;
}
.my-brand {
  display: flex;
  align-items: center;
}
.my-brand h5 {
  font-size: 1.8rem;
  font-weight: 700;
}

.gosticky {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  transition-duration: 500ms;
}
.gosticky .nav-link a,
.gosticky .navbar-brand h5 {
  color: #000 !important;
}
.gosticky a.btn-contact-nav {
  display: flex;
  background: #2e74ff;
  color: #ffffff !important;
  padding: 12px 18px;
  border-radius: 28px;
  font-size: 12pt !important;
  transition-duration: 900ms;
}
.gosticky a.btn-contact-nav:hover {
  background-color: #0965c7;
  transition: all 0.4s ease-in-out;
}
@media only screen and (min-width: 771px) {
  .nav-link a {
    color: #c5c5c5 !important;
    font-weight: 600;
    font-size: 14pt;
    margin-right: 2rem;
    text-decoration: none;
  }
  .navbar-brand h5 {
    color: #ffffff;
  }
  a.btn-contact-nav {
    display: none;
  }
}
@media only screen and (max-width: 770px) {
  .navbar {
    background-color: #ffffff;
    display: block;
    position: fixed;
    padding: 0 1rem;
  }
  .my-brand h5 {
    font-size: 1.4rem;
  }
  .navbar-brand img {
    width: 6rem;
  }
  .navbar-light .navbar-toggler {
    border: none;
  }
  a.btn-contact-nav {
    width: fit-content;
    margin-left: 1rem;
  }
  .nav-link a,
  .navbar-brand h5 {
    color: #000 !important;
  }
  a.btn-contact-nav {
    display: flex;
    background: #2e74ff;
    color: #ffffff !important;
    padding: 12px 18px;
    border-radius: 28px;
    font-size: 12pt !important;
    transition-duration: 900ms;
  }
}
</style>