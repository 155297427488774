<template>
  <div id="product">
    <b-container>
      <h1 class="text-center">Our Awesome Products</h1>
      <hr />
      <p class="text-center">
        Our company does its work with its customer in mind. That is why you<br />
        are getting the best product that will perfectly suit your needs.
      </p>
    </b-container>
    <b-row class="our-products mt-5">
      <b-col md="6" class="the-product cp-1">
        <a href="http://epengadaan.id" target="_blank">
          <div class="shadow"></div>
          <div class="product-name">
            <h2>Epengadaan.id</h2>
          </div>
        </a>
      </b-col>
      <b-col md="6" class="the-product cp-2">
        <a href="http://kehadiran.id" target="_blank">
          <div class="shadow"></div>
          <div class="product-name">
            <h2>Kehadiran.id</h2>
          </div>
        </a>
      </b-col>
      <b-col md="6" class="the-product cp-3">
        <a href="http://konsultasidokter.id" target="_blank">
          <div class="shadow"></div>
          <div class="product-name">
            <h2>Konsultasidokter.id</h2>
          </div>
        </a>
      </b-col>
      <b-col md="6" class="the-product cp-4">
        <a href="http://epersuratan.id" target="_blank">
          <div class="shadow"></div>
          <div class="product-name">
            <h2>Epersuratan.id</h2>
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#product {
  margin-top: 4rem;
  padding-top: 4rem;
  background-color: #f7f9fc;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
hr {
  border-top: 1px solid #d2d2d2;
  width: 20%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
#product h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #191919;
}
.product-item {
  margin-bottom: 1.5rem;
  margin-top: 4rem;
  background-color: #182858;
}
.product-item-1 {
  background-image: url("~@/assets/img/mrt.png");
  background-size: cover;
  background-position: bottom;
}
.product-item-2 {
  background-image: url("~@/assets/img/bumn.png");
  background-size: cover;
  background-position: bottom;
}
.product-item-3 {
  background-image: url("~@/assets/img/bg-inmed.jpg");
  background-size: cover;
  background-position: bottom;
}
.product-item-4 {
  background-image: url("~@/assets/img/bg-bumn.jpg");
  background-size: cover;
  background-position: bottom;
}

.product-item-1 .color-bg {
  background-color: rgb(29 55 80 / 81%);
  padding: 4rem;
}
.product-item-2 .color-bg {
  background-color: rgb(29 55 80 / 81%);
  padding: 4rem;
}
.product-item-3 .color-bg {
  background-color: rgb(29 55 80 / 81%);
  padding: 4rem;
}
.product-item-4 .color-bg {
  background-color: rgb(29 55 80 / 81%);
  padding: 4rem;
}

.product-item-2 {
  margin-top: -5rem;
}
.product-item-4,
.product-item-5 {
  margin-top: -14.5rem;
}
.product-left {
  margin-left: 6rem;
  margin-right: 2rem;
}
.product-right {
  margin-right: 6rem;
  margin-left: 2rem;
}
.product-item .phone-show {
  width: 65%;
}
.product-item .macbook-show {
  width: 100%;
}

.shadow {
  background-color: #4169e1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s ease-out;
  opacity: 0;
}
.the-product {
  cursor: pointer;
}
.the-product:hover .shadow {
  opacity: 0.8;
}
.cp-1 {
  background-image: url("~@/assets/img/mrt.png");
  background-size: cover;
  background-position: center;
  height: 650px;
}
.cp-2 {
  background-image: url("~@/assets/img/bumn.png");
  background-size: cover;
  background-position: center;
  height: 650px;
}
.cp-3 {
  background-image: url("~@/assets/img/inmed.png");
  background-size: cover;
  background-position: center;
  height: 650px;
}
.cp-4 {
  background-image: url("~@/assets/img/garuda.png");
  background-size: cover;
  background-position: center;
  height: 650px;
}

@media only screen and (max-width: 600px) {
  #product h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }
  .cp-1,
  .cp-2,
  .cp-3,
  .cp-4 {
    height: 375px;
    margin: 1rem 0;
  }
  .product-name {
    background-color: white;
    width: fit-content;
    margin: auto;
    padding: 0.4rem 1.5rem 0.3rem;
    margin-top: -1rem;
    box-shadow: 0px 5px 18px 1px rgb(0 0 0 / 10%);
  }
  .our-products h2 {
    font-size: 1.3rem;
    font-weight: 500;
    color: #3a3a3a;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) {
  .our-products h2 {
    font-size: 2.5rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  .product-name {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transition: all ease 0.4s;
    opacity: 0;
    height: auto;
    transform: translate(-50%, -50%);
  }
  .the-product:hover .product-name {
    opacity: 1;
    top: 45%;
  }
}
@media only screen and (min-width: 1600px) {
  .cp-1,
  .cp-2,
  .cp-3,
  .cp-4 {
    height: 950px;
  }
}
</style>