<template>
  <div id="hero-section">
    <div id="hero-content">
      <b-container>
        <b-row align-v="center">
          <b-col class="mx-auto text-center" md="8">
            <h1 class="web-title">IT Consultant Cendana 2000</h1>
            <p class="my-4">
              Many year experience and hundreds of successful projects have made
              Us a professional on the web area.
            </p>
            <a
              href="https://wa.me/6281334726516"
              target="_blank"
              class="btn btn-lg btn-join"
              >CONTACT US</a
            >
            <!-- <a
              href="https://play.google.com/store/apps/details?id=com.pmi.tangerang"
              target="_blank"
              ><img
                :src="require('@/assets/btn-google-play.png')"
                alt="Temukan di Google Play"
                class="btn-google-play"
            /></a>
            <a
              href="https://apps.apple.com/us/app/sidoni-aplikasi-donor-darah/id1487058219?"
              target="_blank"
              ><img
                :src="require('@/assets/btn-app-store.svg')"
                alt="Download on the App Store"
                class="btn-app-store"
            /></a> -->
          </b-col>
          <!-- <b-col class="hero-right" md="5"> -->
          <!-- <b-img
              class="phone-design"
              :src="require('@/assets/showcase-sidoni.png')"
            ></b-img> -->
          <!-- </b-col> -->
        </b-row>
      </b-container>
      <!-- <b-img
        class="shape-hero"
        :src="require('@/assets/shape-hero.svg')"
      ></b-img> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#hero-section {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  background-image: url("~@/assets/img/bg-home.jpg");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}
#hero-section h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #ffffff;
}
#hero-section p {
  color: #fefefe;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
#hero-section h4 {
  color: #ffffff;
}
#hero-content {
  /* background-color: rgb(33, 136, 218, 0.85); */
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
.hero-right {
  z-index: 9;
  text-align: center;
}
.phone-design {
  height: 32rem;
  transition: all 0.4s ease-in-out;
}
.phone-design:hover {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  height: 34rem;
}
.shape-hero {
  position: absolute;
  bottom: -1px;
  width: 100%;
}
a .btn-google-play {
  width: 248px;
  margin-left: -1rem;
}
a .btn-app-store {
  width: 190px;
}
a.btn-join {
  background-color: #00b0ff;
  color: #ffffff;
  border-radius: 28px;
  padding: 0.6rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}
a.btn-join:hover {
  background-color: #4169e1;
  transition: all 0.4s ease-in-out;
}
@media only screen and (min-width: 601px) {
  .hero-left .mobile-title {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  /* #hero-section,
  #hero-content {
    height: 100%;
  } */
  #hero-content {
    text-align: center;
  }
  .hero-left {
    padding-top: 7rem;
  }
  .hero-left .web-title {
    display: none;
  }
  #hero-section h1 {
    font-size: 1.8rem;
  }
  #hero-section h4 {
    font-size: 1.2rem;
  }
  #hero-section p {
    font-size: 1rem;
  }
  a .btn-google-play {
    width: 170px;
  }
  a .btn-app-store {
    width: 130px;
  }
  .phone-design {
    height: 28rem;
    padding-top: 2rem;
  }
}
/* @media only screen and (min-width: 1600px) {
  #hero-content {
    height: 75vh;
  }
} */
</style>
