<template>
  <div id="footer-section">
    <b-container>
      <b-row class="footer-content">
        <b-col sm="12" md="3">
          <div class="footer-center">
            <div class="footer-address mb-2">
              <h4>Address</h4>
              <div class="address">
                <div class="address-icon">
                  <unicon name="map-marker" /> Jalan PLN Raya nomor 95 Gandul,
                  Cinere, Depok 16512
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="3">
          <div class="footer-center">
            <div class="footer-address mb-2">
              <h4>Contact Number</h4>
              <a href="https://wa.me/6281334726516">
                <div class="address">
                  <div class="address-icon">
                    <span><unicon name="whatsapp" /></span>
                    +6281334726516
                  </div>
                </div>
              </a>
              <a href="https://wa.me/6285336280000">
                <div class="address">
                  <div class="address-icon">
                    <span><unicon name="whatsapp" /></span>
                    +6285336280000
                  </div>
                </div>
              </a>
              <h4 class="mt-3 mb-2">Email</h4>
              <a href="mailto:rizky@cendana2000.co.id">
                <div class="address">
                  <div class="address-icon">
                    <span><unicon name="envelope" /></span>
                    rizky@cendana2000.co.id
                  </div>
                </div>
              </a>
              <a href="mailto:wardi@cendana2000.co.id">
                <div class="address">
                  <div class="address-icon">
                    <span><unicon name="envelope" /></span>
                    wardi@cendana2000.co.id
                  </div>
                </div>
              </a>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="3">
          <div class="footer-center">
            <div class="footer-link mb-2">
              <h4>Our Product</h4>
              <ul>
                <a href="http://epengadaan.id">
                  <li class="pt-2">E-Pengadaan</li>
                </a>
                <a href="http://epersuratan.id">
                  <li class="pt-2">E-Persuratan</li>
                </a>
                <a href="http://konsultasidokter.id">
                  <li class="pt-2">Konsultasi Dokter</li>
                </a>
                <a href="http://kehadiran.id">
                  <li class="pt-2">Kehadiran Online</li>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="3">
          <div class="footer-center">
            <div class="footer-link mb-2">
              <h4>Certificate</h4>
              <div class="iso">
                <b-img
                  class="phone-design"
                  :src="require('@/assets/iso.png')"
                ></b-img>
              </div>
              <div class="iso mt-2">
                <b-img
                  class="phone-design"
                  :src="require('@/assets/iso-2.png')"
                ></b-img>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="copyright">
        <p>Copyrights © 2021 All rights reserved by Cendana2000</p>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#footer-section {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  position: relative;
  color: #313131;
}
#footer-section .copyright {
  border-top: 1px solid #555555;
  margin-top: 2rem;
  padding-top: 1.5rem;
  text-align: center;
}
#footer-section a {
  color: #313131;
}
#footer-section a:hover {
  color: #4f64da;
  text-decoration: none;
}
.shape-footer {
  width: 100%;
  position: absolute;
  top: 0;
}
.footer-content {
  margin-top: 7rem;
}
.footer-link ul {
  padding-left: 1rem;
}
.footer-content h4 {
  margin-bottom: 1rem;
}
.footer-left span {
  margin-right: 0.5rem;
}
.footer-left img {
  margin-bottom: 1rem;
}
.footer-content h4 {
  margin-bottom: 1.5rem;
}
a .btn-google-play {
  width: 200px;
  margin-left: -1rem;
}
a .btn-app-store {
  width: 156px;
}
/* .address {
  display: flex;
} */
.footer-center .unicon {
  margin-right: 5px;
}
.iso {
  padding: 1rem;
  background: white;
  width: fit-content;
  border-radius: 10px;
}
.iso img {
  width: 10rem;
}
@media only screen and (max-width: 600px) {
  .shape-footer {
    top: -2px;
  }
  .footer-content {
    margin-top: 5rem;
  }
  .footer-left,
  .footer-center,
  .footer-right {
    margin-bottom: 2rem;
  }
  a .btn-google-play {
    width: 190px;
  }
  a .btn-app-store {
    width: 140px;
  }
}
@media only screen and (min-width: 1600px) {
  .footer-content {
    margin-top: 16rem;
  }
}
</style>